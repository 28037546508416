import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { LicenseInstallerActions } from '../../store'
import mapStateToProps from './licenseInstallerModal.selector'
import LicenseInstallerModal from './licenseInstallerModal'
import { LicensesActions } from '../../../Licenses/store'

const mapDispatchToProps = dispatch => ({
  saveBundle: () => dispatch(LicenseInstallerActions.saveBundle()),
  scheduleBundle: () => dispatch(LicenseInstallerActions.scheduleBundle()),
  resetLicenseInstaller: () => dispatch(LicenseInstallerActions.reset()),
  handleBack: () => dispatch(LicenseInstallerActions.back()),
  refreshLicenses: () => dispatch(LicensesActions.getLicenses()),
  refreshLicenseBundles: () => dispatch(LicensesActions.getLicenseBundles()),
  setInstallNowScheduleType: () => dispatch(LicenseInstallerActions.updateScheduleType(1))
})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(LicenseInstallerModal)

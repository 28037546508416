/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState, useRef } from 'react'
import moment from 'moment-timezone'
import {
  StyledScheduleContainer,
  StyledDiv,
  StyledSelectContainer,
  StyledRadio,
  StyledTimeZoneSelect,
  StyledDatePicker,
  ScheduleDateTime,
  StyledTimeSelect,
  ScheduleDateTitle,
  ScheduleDateRangeBox,
  ScheduleSeparator,
  StyledInput

} from './schedule.styles'

import tz from './time-zones.json'
import tm from './time.json'
import InstallLaterMessage from './installLater'
import InstallNowMessage from './installNow'
import { isNull } from 'lodash'

const Schedule = ({
  scheduleDetails,
  updateScheduleType,
  updateScheduleZoneAndDatetime,
  updateScheduleZoneAndToDatetime
}) => {
  const {
    scheduleType,
    scheduleTimezone,
    scheduleFromDatetime,
    scheduleFromDate,
    scheduleFromTime,
    scheduleToDate,
    scheduleToTime,
    scheduleToDatetime
  } = scheduleDetails

  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const currentDt = moment.utc().format('MM/DD/YYYY')
  const momentTime = moment.utc().format('HH:mm')
  const time = momentTime.split(':')
  const hours = time[0]
  const min = time[1]
  const currentTm = (hours > 12) ? (hours - 12 + ':' + min + ' PM') : (hours + ':' + min + ' AM')

  const [selectedScheduleOption, setSelectedScheduleOption] = useState(scheduleType)
  const [timeZone, setTimeZone] = useState(scheduleTimezone)

  const [datetime, setDatetime] = useState(scheduleFromDatetime)
  const [toDatetime, setToDatetime] = useState(scheduleToDatetime)

  const [fromDate, setFromDate] = useState(getDateFromMoment(scheduleFromDate))
  const [fromTime, setFromTime] = useState(scheduleFromTime)

  const [toDate, setToDate] = useState(getDateFromMoment(scheduleToDate))
  const [toTime, setToTime] = useState(scheduleToTime)

  const [currentTime, setCurrentTime] = useState(currentTm)
  const [currentDate, setCurrentDate] = useState(currentDt)

  const [errorMsg, setErrorMsg] = useState('')
  const [timeZoneError, setTimeZoneError] = useState('')
  const [toTimeError, setToTimeError] = useState('')
  const [fromTimeError, setFromTimeError] = useState('')

  const handleScheduleType = useCallback(
    (type) => {
      updateScheduleType(type)
      setSelectedScheduleOption(type)
      setTimeZone(null)
      setDatetime(null)
      setToDatetime(null)
      setFromDate('')
      setFromTime('')
      setToDate('')
      setToTime('')
      setToTimeError('')
      setFromTimeError('')
      const momentTime = moment.tz(moment(), timeZone).format('HH:mm')
      //moment.tz.setDefault(timeZone)
      const time = momentTime.split(':')
      const hours = time[0]
      const min = time[1]
      const currentTm = (hours > 12) ? (hours - 12 + ':' + min + ' PM') : (hours + ':' + min + ' AM')
      setCurrentTime(currentTm)
      const currentDateUpdate = moment.tz(moment(), timeZone).format('MM/DD/YYYY HH:mm')
      setCurrentDate(currentDateUpdate)
    }, [updateScheduleType, setSelectedScheduleOption])

  function getDateFromMoment (val) {
    if (val) {
      const dateValue = moment.tz(val, timeZone)
      return dateValue.toDate()
    } else {
      return null
    }
  }

  const _changeTimeZone = (newTimeZone) => {
    setTimeZone(newTimeZone)
    // moment.tz.setDefault(newTimeZone)
    setDatetime('')
    setToDatetime('')
    if (selectedScheduleOption === 1) { // Immediate option
      setToDate(currentDt)
    } else {
      setToDate('')
    }
    setToTime('')
    setFromDate(currentDt)
    setFromTime('')
    const momentTime = moment.tz(moment(), newTimeZone).format('HH:mm')
    moment.tz.setDefault(newTimeZone)
    const time = momentTime.split(':')
    const hours = time[0]
    const min = time[1]
    const currentTm = (hours > 12) ? (hours - 12 + ':' + min + ' PM') : (hours + ':' + min + ' AM')
    setCurrentTime(currentTm)
    const currentDateUpdate = moment.tz(moment(), newTimeZone).format('MM/DD/YYYY HH:mm')
    setCurrentDate(currentDateUpdate)
    const today = new Date()
    if (!fromDate && !fromTime) {
      handleCurrentDateAndTime(today, momentTime)
    } else {
      updateScheduleZoneAndDatetime(newTimeZone, null, null, null)
    }
    updateScheduleZoneAndToDatetime(newTimeZone, null, null, null)
  }

  function handleCurrentDateAndTime (scheduleCurrentDate, scheduleCurrentTime) {
    const currentDateVal = moment(scheduleCurrentDate)
    const currentdate = moment(scheduleCurrentDate)
    const hours = scheduleCurrentTime.split(':')[0]
    const minutes = scheduleCurrentTime.split(':')[1]
    currentDateVal.set('hour', 0)
    currentDateVal.set('minute', 0)
    currentDateVal.set('second', 0)
    currentDateVal.add(parseInt(hours), 'hours').add(parseInt(minutes), 'minutes')
    updateScheduleZoneAndDatetime(timeZone, currentDateVal.format(), currentdate.format(), scheduleCurrentTime)
  }

  function handleFromDateAndTime (scheduleFromDate, scheduleFromTime) {
    const fromDateVal = moment(scheduleFromDate)
    const fromdate = moment(scheduleFromDate)
    fromDateVal.set('hour', 0).set('minute', 0).set('second', 0)
    handleHours(fromDateVal, scheduleFromTime)
    if (fromDateVal.isValid()) {
      setDatetime(fromDateVal)
    }
    validateTimeSelected()
    validateTime(fromDateVal, toDatetime, scheduleFromTime, toTime)
    updateScheduleZoneAndDatetime(timeZone, fromDateVal.format(), fromdate.format(), scheduleFromTime)
  }

  const _handleFromDateChange = (date) => {
    setFromDate(date)
    handleFromDateAndTime(date, fromTime)
  }

  const _handleFromTime = (time) => {
    setFromTime(time)
    handleFromDateAndTime(fromDate, time)
  }

  function handleHours (dateTimeMoment, hours) {
    let hr = parseInt(hours)
    if (hr == 24) {
      hr = 0
    }
    dateTimeMoment.add(hr, 'hours')
  }

  function handleToDateAndTime (scheduleToDate, scheduleToTime) {
    const toDateVal = moment(scheduleToDate)
    const todate = moment(scheduleToDate)
    toDateVal.set('hour', 0).set('minute', 0).set('second', 0)
    handleHours(toDateVal, scheduleToTime)
    if (toDateVal.isValid()) {
      setToDatetime(toDateVal)
    }
    validateTimeSelected()
    validateTime(datetime, toDateVal, fromTime, scheduleToTime)
    updateScheduleZoneAndToDatetime(timeZone, toDateVal.format(), todate.format(), scheduleToTime)
  }

  const _handleToDateChange = (date) => {
    setToDate(date)
    handleToDateAndTime(date, toTime)
  }

  const _handleToTime = (time) => {
    setToTime(time)
    handleToDateAndTime(toDate, time)
  }

  const validateTimeSelected = () => {
    if (timeZone === null) {
      setTimeZoneError('Select a time zone')
      return false
    } else {
      setTimeZoneError('')
      return true
    }
  }

  const validateTime = (datetime, toDatetime, fromTime, toTime) => {
    if (datetime) {
      const todayDate = new Date()
      const currentDateVal = moment.tz(todayDate, timeZone)
      if (currentDateVal.diff(datetime) > 0 && fromTime > 0) {
        setFromTimeError('The current hour is in the past.')
        return false
      }
      setFromTimeError('')
    }

    if (datetime && toDatetime) {
      if (datetime.diff(toDatetime) > 0 && fromTime > 0 && toTime > 0) {
        setToTimeError('The hour is in the past.')
        return false
      }
      setToTimeError('')
    }
    return true
  }

  return (
    <StyledScheduleContainer>
      <StyledDiv>
        When do you want to install the licenses?
      </StyledDiv>
      <StyledSelectContainer>
        <StyledRadio
          label='Immediate'
          name='ScheduleGroup'
          checked={selectedScheduleOption === 1}
          onChange={() => handleScheduleType(1)}
        />
        <StyledRadio
          label='Schedule'
          name='ScheduleGroup'
          checked={selectedScheduleOption === 3}
          onChange={() => handleScheduleType(3)}
        />
        {/* <StyledRadio
          label='Save bundle for later use'
          name='ScheduleGroup'
          checked={selectedScheduleOption === 2}
          onChange={() => handleScheduleType(2)}
        /> */}
      </StyledSelectContainer>
      {selectedScheduleOption == 3
        ? <>
          <StyledTimeZoneSelect
            label='Time zone'
            placeholder='Select a time zone'
            value={timeZone}
            error={timeZoneError}
            options={tz}
            onChange={_changeTimeZone}
            disabled={selectedScheduleOption === 1}
            indicator='required'
            search
          />
          <ScheduleDateTitle>Schedule Date</ScheduleDateTitle>
          <ScheduleDateTime>
            <ScheduleDateRangeBox>
              <StyledDatePicker
                label='Starts'
                type='date'
                value={fromDate || currentDt}
                key={timeZone}
                timezone={timeZone}
                onChange={_handleFromDateChange}
                highlightWeekends={true}
                disablePast={true}
                closeOnDocumentClick={true}
                disabled={selectedScheduleOption === 1 || !timeZone}
                indicator='required'
                reserveSpace={true}
              />
              <StyledDatePicker
                label='Ends'
                type='date'
                value={toDate}
                // key={tz}
                timezone={timeZone}
                onChange={_handleToDateChange}
                highlightWeekends={true}
                disablePast={true}
                closeOnDocumentClick={true}
                disabled={selectedScheduleOption === 1 || !timeZone}
                indicator='required'
                reserveSpace={true}
              />
            </ScheduleDateRangeBox>
            <ScheduleSeparator />
            <ScheduleDateRangeBox>
              <StyledTimeSelect
                label='From'
                value={fromTime}
                error={fromTimeError}
                options={tm}
                onChange={_handleFromTime}
                disabled={selectedScheduleOption === 1 || !timeZone}
                indicator='required'
              />
              <StyledTimeSelect
                label='To'
                value={toTime}
                error={toTimeError}
                options={tm}
                onChange={_handleToTime}
                disabled={selectedScheduleOption === 1 || !timeZone}
                indicator='required'
              />
            </ScheduleDateRangeBox>
          </ScheduleDateTime>

          </>

        : (selectedScheduleOption == 2 ? <InstallLaterMessage /> : <>
          <StyledTimeZoneSelect
            label='Time zone'
            placeholder='Select a time zone'
            value={timeZone}
            error={timeZoneError}
            options={tz}
            onChange={_changeTimeZone}
            disabled={selectedScheduleOption === 3}
            indicator='required'
            search
          />
          <ScheduleDateTitle>Schedule Date</ScheduleDateTitle>
          <ScheduleDateTime>
            <ScheduleDateRangeBox>
              <StyledDatePicker
                label='Starts'
                type='date'
                value={currentDate}
                key={timeZone}
                timezone={timeZone}
                onChange={_handleFromDateChange}
                highlightWeekends={true}
                disablePast={true}
                closeOnDocumentClick={true}
                disabled={selectedScheduleOption === 1 || !timeZone}
                indicator='required'
                reserveSpace={true}
              />
              <StyledInput
                label='From'
                value={currentTime}
                error={timeZoneError}
                disabled={selectedScheduleOption === 1 || !timeZone}
                indicator='required'
              />
            </ScheduleDateRangeBox>
            <ScheduleSeparator />
            <ScheduleDateRangeBox>
              <StyledDatePicker
                label='Ends'
                type='date'
                value={toDate || currentDt}
                // key={tz}
                timezone={timeZone}
                onChange={_handleToDateChange}
                highlightWeekends={true}
                disablePast={true}
                closeOnDocumentClick={true}
                disabled={selectedScheduleOption === 3 || !timeZone}
                indicator='required'
                reserveSpace={true}
              />
              <StyledTimeSelect
                label='To'
                value={toTime}
                options={tm}
                onChange={_handleToTime}
                disabled={selectedScheduleOption === 3 || !timeZone}
                indicator='required'
              />
            </ScheduleDateRangeBox>
          </ScheduleDateTime>
                                                                   </>)}
    </StyledScheduleContainer>)
}

export default React.memo(Schedule)

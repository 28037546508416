import config from 'Config/AppConfig'
import { ORG_FILTERED_DEVICE_COUNT, SITE_FILTERED_DEVICE_COUNT, SITE_FILTER_TYPE } from '../licenseInstaller.constants'

export class LicenseInstallerService {
  constructor (api) {
    this.api = api
    if (config.nonSinaps) 
      this.licenseController ='/LicenseManagementSA'
    else
      this.licenseController ='/LicenseManagement'
  }

  getDevices = ({ filterData, filterType, pageNumber }) => {
    const paginationLimit = (filterType == SITE_FILTER_TYPE) ? SITE_FILTERED_DEVICE_COUNT : ORG_FILTERED_DEVICE_COUNT
    if (config.nonSinaps) {
      return this.api.get('LicenseManagementSA/devices')
    } else {
      return this.api.get('/v2/devices', {
        filter: JSON.stringify(filterData),
        pageNumber: pageNumber,
        pageSize: paginationLimit,
        profileid:'684D492B-CE98-43CF-AEEF-F333A84440BF'
      })
    }
  }

  getv4Devices = ({ pageSize, filterData }) => {
    return this.api.post('/v4/devices', {
      filter: JSON.stringify(filterData),
      pageSize: pageSize,
      profileid:'684D492B-CE98-43CF-AEEF-F333A84440BF'
    })
  }

  getSites = ({ sited }) => {    
      return this.api.get('/groupmgmt/OrgUnits/swfwupdate')
  }

  insertBundle = params => this.api.post(this.licenseController + '/licensebundles', params)
  scheduleBundle = params => this.api.post('/schedules', params)
}
